.root {
  font-size: clamp(1rem, 1.4583333333vw, 100rem);
  height: 1.33em;
  letter-spacing: 0.025em;
  padding: 0 0.7em;
  background: #ebff00;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 111px;
  box-shadow: 0.05vw 0.05vw 0.1vw rgba(0, 0, 0, 0.5), 0px 0px 0.26vw rgba(0, 0, 0, 0.4);
}