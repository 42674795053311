.root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  background-color: black;
  font-size: clamp(0.8rem, 1.6666666667vw, 100rem);
  padding: 9rem;
  text-align: center;
}

.form {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input {
  font-size: clamp(0.8rem, 1.6666666667vw, 100rem);
}

.confirm {
  font-size: clamp(0.8rem, 1.6666666667vw, 100rem);
}

.title {
  margin-top: 0;
}