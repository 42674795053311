.root {
  appearance: none;
  font-size: clamp(1rem, 2.9661016949vw, 999rem);
  font-size: clamp(0.8rem, 3.5593220339vw, 99rem);
  height: 1.5em;
  letter-spacing: 0.02em;
  padding: 0 0.4em 0 0.5em;
  border-radius: 1111px;
  white-space: nowrap;
  background: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  font-family: "Blur";
  cursor: pointer;
  position: fixed;
  bottom: clamp(1rem, 2.9661016949vw, 999rem);
  border-radius: 111px;
  color: #000;
  transition: filter 0.1s ease;
  transition: opacity var(--global-transition-duration) ease;
  transition: opacity var(--global-transition-duration) ease, transform var(--global-transition-duration) ease;
  opacity: 0;
  transform: translateY(3vw);
  white-space: nowrap;
}
.root > svg {
  height: 55%;
  width: auto;
  margin-left: 0.5rem;
  padding: 1px;
  flex-shrink: 0;
}
.root:global(.enter) {
  opacity: 0;
  transition-delay: calc(0ms + var(--global-transition-duration) * 0.75);
  transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  will-change: opacity;
  transform: translateY(3vw);
}
.root:global(.appear-active), .root:global(.appear-done), .root:global(.enter-active), .root:global(.enter-done) {
  opacity: 1;
  transform: translateY(0);
}
.root:global(.exit) {
  opacity: 1;
  will-change: opacity;
  transition-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
  transition-delay: 0ms;
  transform: translateY(0);
}
.root:global(.exit-active) {
  opacity: 0;
  transform: translateY(-3vw);
}
.root:global(.exit-done) {
  display: none !important;
}

.root:before {
  width: 150%;
  height: 200%;
  left: -25%;
  top: -50%;
  content: "";
  display: block;
  position: absolute;
}

.root:active {
  filter: invert(1);
}

.root--grey {
  background: #202020;
  color: #fff;
}

.root--left {
  left: clamp(1rem, 2.9661016949vw, 999rem);
}

.root--right {
  right: clamp(1rem, 2.9661016949vw, 999rem);
  background-color: #ebff00;
  box-shadow: 0px 0px 30px #ebff00;
}