.root {
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
}

.inner {
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
  transition: opacity var(--global-transition-duration) ease;
  transition: opacity var(--global-transition-duration) ease, transform var(--global-transition-duration) ease;
  opacity: 0;
  transform: translateY(3vw);
}
.inner:global(.enter) {
  opacity: 0;
  transition-delay: calc(0ms + var(--global-transition-duration) * 0.75);
  transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  will-change: opacity;
  transform: translateY(3vw);
}
.inner:global(.appear-active), .inner:global(.appear-done), .inner:global(.enter-active), .inner:global(.enter-done) {
  opacity: 1;
  transform: translateY(0);
}
.inner:global(.exit) {
  opacity: 1;
  will-change: opacity;
  transition-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
  transition-delay: 0ms;
  transform: translateY(0);
}
.inner:global(.exit-active) {
  opacity: 0;
  transform: translateY(-3vw);
}
.inner:global(.exit-done) {
  display: none !important;
}

.reset {
  position: fixed;
  bottom: 0;
  left: 0;
}