.root {
  position: fixed;
  display: inline-block;
  bottom: clamp(0.8rem, 2.34375vw, 100rem);
  width: fit-content;
  max-width: 70vw;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  transition: opacity var(--global-transition-duration) ease;
  transition: opacity var(--global-transition-duration) ease, transform var(--global-transition-duration) ease;
  opacity: 0;
  transform: translateY(3vw);
}
.root:global(.enter) {
  opacity: 0;
  transition-delay: calc(0ms + var(--global-transition-duration) * 0.75);
  transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  will-change: opacity;
  transform: translateY(3vw);
}
.root:global(.appear-active), .root:global(.appear-done), .root:global(.enter-active), .root:global(.enter-done) {
  opacity: 1;
  transform: translateY(0);
}
.root:global(.exit) {
  opacity: 1;
  will-change: opacity;
  transition-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
  transition-delay: 0ms;
  transform: translateY(0);
}
.root:global(.exit-active) {
  opacity: 0;
  transform: translateY(-3vw);
}
.root:global(.exit-done) {
  display: none !important;
}

.message {
  font-size: clamp(2rem, 2.5vw, 100rem);
  line-height: 1.16em;
  text-align: center;
  color: #fff;
  margin: 0;
  text-shadow: 0.05vw 0.05vw 0.1vw rgba(0, 0, 0, 0.5), 0px 0px 0.26vw rgba(0, 0, 0, 0.4);
  width: fit-content;
}

.heading {
  margin-bottom: clamp(0.8rem, 0.5208333333vw, 100rem);
  margin-bottom: clamp(0.8rem, 2.0833333333vw, 100rem);
}