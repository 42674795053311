.root {
  background: #ebff00;
  font-size: clamp(1.25rem, 2.0833333333vw, 100rem);
  height: 1.4em;
  padding: 0 0.35em;
  border-radius: 1111px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.root--yellow {
  color: #000;
  background: #ebff00;
  box-shadow: 0px 0px 30px #ebff00;
}

.root--blue {
  background: #7ef7e8;
  box-shadow: 0px 0px 30px #7ef7e8;
}

.type {
  margin-right: clamp(0.8rem, 0.4166666667vw, 100rem);
  line-height: 1em;
}

.quantity {
  border: 2px solid black;
  border-radius: 50%;
  font-size: clamp(0.8rem, 1.0416666667vw, 100rem);
  width: 1.5em;
  height: 1.5em;
  line-height: 1em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  0% {
    transform: scale(1);
  }
}
.root--bounce {
  animation: bounce 0.5s ease-in-out;
}

.root > svg {
  height: 60%;
  width: auto;
  margin-right: 0.3em;
}

.root > svg path {
  fill: #000;
}