.root {
  position: fixed;
  top: clamp(0.8rem, 1.0416666667vw, 100rem);
  left: clamp(0.8rem, 1.0416666667vw, 100rem);
  transition: opacity var(--global-transition-duration) ease;
  transition: opacity var(--global-transition-duration) ease, transform var(--global-transition-duration) ease;
  opacity: 0;
  transform: translateY(3vw);
}
.root:global(.enter) {
  opacity: 0;
  transition-delay: calc(0ms + var(--global-transition-duration) * 0.75);
  transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  will-change: opacity;
  transform: translateY(3vw);
}
.root:global(.appear-active), .root:global(.appear-done), .root:global(.enter-active), .root:global(.enter-done) {
  opacity: 1;
  transform: translateY(0);
}
.root:global(.exit) {
  opacity: 1;
  will-change: opacity;
  transition-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
  transition-delay: 0ms;
  transform: translateY(0);
}
.root:global(.exit-active) {
  opacity: 0;
  transform: translateY(-3vw);
}
.root:global(.exit-done) {
  display: none !important;
}

.header {
  margin-left: clamp(6rem, 5.7291666667vw, 100rem);
  margin-bottom: 0;
}

.main {
  font-size: clamp(1rem, 1.25vw, 100rem);
  padding: 0;
  padding-right: clamp(0.8rem, 0.8333333333vw, 100rem);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -3ch;
  position: relative;
}

.main:before {
  content: "";
  font-size: inherit;
  height: 3ch;
  width: 100%;
  display: block;
  background-color: rgba(32, 32, 32, 0.4);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 111px;
  border: 2px solid rgba(255, 255, 255, 0.4);
  box-shadow: 0.05vw 0.05vw 0.1vw rgba(0, 0, 0, 0.5), 0px 0px 0.26vw rgba(0, 0, 0, 0.4);
}

.name {
  position: relative;
  margin-left: -0.8em;
}

.image {
  height: 13ch;
  width: auto;
  position: relative;
  margin-left: -0.5em;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.5)) drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.4));
}