.root {
  position: fixed;
  top: 70%;
  left: 0;
  right: 0;
  width: 42%;
  height: auto;
  margin: auto;
  transition: all 0.8s ease;
  opacity: 0;
  transform: translateY(33%);
}
.root:global(.enter) {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  will-change: opacity;
  transform: translateY(33%);
}
.root:global(.appear-active), .root:global(.appear-done), .root:global(.enter-active), .root:global(.enter-done) {
  opacity: 1;
  transform: translateY(0);
}
.root:global(.exit) {
  opacity: 1;
  will-change: opacity;
  transition-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
  transform: translateY(0);
}
.root:global(.exit-active) {
  opacity: 0;
  transform: translateY(33%);
}
.root:global(.exit-done) {
  display: none !important;
}