.step {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: clamp(0.8rem, 6.6666666667vw, 100rem);
  color: #ffffff;
  text-align: center;
  transition: opacity var(--global-transition-duration) ease;
  transition: opacity var(--global-transition-duration) ease, transform var(--global-transition-duration) ease;
  opacity: 0;
  transform: translateY(3vw);
}
.step:global(.enter) {
  opacity: 0;
  transition-delay: calc(0ms + var(--global-transition-duration) * 0.75);
  transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  will-change: opacity;
  transform: translateY(3vw);
}
.step:global(.appear-active), .step:global(.appear-done), .step:global(.enter-active), .step:global(.enter-done) {
  opacity: 1;
  transform: translateY(0);
}
.step:global(.exit) {
  opacity: 1;
  will-change: opacity;
  transition-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
  transition-delay: 0ms;
  transform: translateY(0);
}
.step:global(.exit-active) {
  opacity: 0;
  transform: translateY(-3vw);
}
.step:global(.exit-done) {
  display: none !important;
}

.title {
  font-size: clamp(0.8rem, 9.6354166667vw, 100rem);
  letter-spacing: -0.01em;
  line-height: 0.8em;
  text-shadow: 0px 0px 20px #ffffff, 0px 0px 20px rgba(255, 255, 255, 0.5);
}

.text {
  font-size: clamp(0.8rem, 2.5vw, 100rem);
  line-height: 1.16em;
  text-shadow: 0.05vw 0.05vw 0.1vw rgba(0, 0, 0, 0.5), 0px 0px 0.26vw rgba(0, 0, 0, 0.4);
}

.name {
  text-shadow: 0.05vw 0.05vw 0.1vw rgba(0, 0, 0, 0.5), 0px 0px 0.26vw rgba(0, 0, 0, 0.4);
  font-size: clamp(0.8rem, 6.25vw, 100rem);
  line-height: 0.92em;
  display: block;
  margin-bottom: clamp(0.8rem, 4.1666666667vw, 100rem);
}

.name-wrapper {
  justify-self: flex-start;
  margin-bottom: auto;
}

.elements {
  width: 60vw;
  margin-top: clamp(0.8rem, 4.1666666667vw, 100rem);
  height: auto;
}
.elements :global(.icon) {
  filter: drop-shadow(0px 0px 20px #fff);
}
.elements :global(.text) {
  filter: drop-shadow(0.05vw 0.05vw 0.1vw rgba(0, 0, 0, 0.5)) drop-shadow(0px 0px 0.26vw rgba(0, 0, 0, 0.4));
}

.character-image {
  width: 30%;
  height: auto;
}