.root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.message {
  font-size: clamp(0.8rem, 1.6666666667vw, 100rem);
  text-shadow: 0.05vw 0.05vw 0.1vw rgba(0, 0, 0, 0.5), 0px 0px 0.26vw rgba(0, 0, 0, 0.4);
  margin-bottom: 4rem;
}

.key {
  font-size: clamp(0.8rem, 9.6354166667vw, 100rem);
  text-shadow: 0.05vw 0.05vw 0.1vw rgba(0, 0, 0, 0.5), 0px 0px 0.26vw rgba(0, 0, 0, 0.4);
  display: flex;
  gap: 1.5rem;
  line-height: 1em;
}

.char {
  display: inline-flex;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 2rem;
  padding: 0.1em;
}