.root {
  position: fixed;
  bottom: clamp(0.8rem, 1.875vw, 100rem);
  left: clamp(0.8rem, 1.0416666667vw, 100rem);
  width: clamp(0.8rem, 16.6666666667vw, 100rem);
  height: clamp(0.8rem, 12.5vw, 100rem);
  transition: opacity var(--global-transition-duration) ease;
  opacity: 0;
}
.root:global(.enter) {
  opacity: 0;
  transition-delay: calc(0ms + var(--global-transition-duration) * 0.75);
  transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  will-change: opacity;
}
.root:global(.appear-active), .root:global(.appear-done), .root:global(.enter-active), .root:global(.enter-done) {
  opacity: 1;
}
.root:global(.exit) {
  opacity: 1;
  will-change: opacity;
  transition-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
  transition-delay: 0ms;
}
.root:global(.exit-active) {
  opacity: 0;
}
.root:global(.exit-done) {
  display: none !important;
}

.heading {
  position: absolute;
  bottom: 0;
  left: 0;
}