.root {
  width: 100%;
  height: 100vw;
  max-width: 100vh;
  max-height: 100vh;
  position: relative;
  margin: auto;
}

.canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: min(5vw, 3.8rem);
}