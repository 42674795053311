.root {
  position: absolute;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  transform: translate(-50%, -50%);
}

.wrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: clamp(0.8rem, 1.0416666667vw, 100rem);
  height: clamp(0.8rem, 1.0416666667vw, 100rem);
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  box-shadow: 0.05vw 0.05vw 0.1vw rgba(0, 0, 0, 0.5), 0px 0px 0.26vw rgba(0, 0, 0, 0.4);
  margin-right: clamp(0.8rem, 0.2604166667vw, 100rem);
}

.wrapper > svg {
  width: auto;
  height: auto;
  max-width: 70%;
  max-height: 70%;
  filter: drop-shadow(0.05vw 0.05vw 0.1vw rgba(0, 0, 0, 0.5)) drop-shadow(0px 0px 0.26vw rgba(0, 0, 0, 0.4));
}

.count {
  font-size: clamp(0.8rem, 0.6510416667vw, 100rem);
  color: #fff;
  text-shadow: 0.05vw 0.05vw 0.1vw rgba(0, 0, 0, 0.5), 0px 0px 0.26vw rgba(0, 0, 0, 0.4);
}

.root--glacier {
  left: 64%;
  top: 5%;
}

.root--tree {
  left: 70%;
  top: 16%;
}

.root--mountain {
  left: 73%;
  top: 29%;
}

.root--river {
  left: 74%;
  top: 42%;
}

.root--bird {
  left: 72%;
  top: 56%;
}

.root--human {
  left: 68%;
  top: 68%;
}

.root--fungi {
  left: 61%;
  top: 78%;
}