.root {
  display: flex;
  flex-grow: 1;
  flex-basis: 100%;
  appearance: none;
  background: transparent;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  font-family: "Blur";
  color: #fff;
  cursor: pointer;
}

.label:not(:last-child) {
  margin-bottom: 3rem;
}

.label {
  font-size: clamp(0.8rem, 3.5593220339vw, 99rem);
  height: 1.5em;
  letter-spacing: 0.02em;
  padding: 0 0.4em 0 0.5em;
  border-radius: 1111px;
  white-space: nowrap;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #000;
  transition: filter 0.1s ease;
}
.label > svg {
  height: 55%;
  width: auto;
  margin-left: 0.5rem;
  padding: 1px;
  flex-shrink: 0;
}

.root:active .label {
  filter: invert(1);
}

.label--none {
  color: #fff;
  text-shadow: 0px 0px 20px #ffffff, 0px 0px 20px rgba(255, 255, 255, 0.5);
  background: transparent;
  box-shadow: none;
  padding: 0;
}

.label--neutral {
  color: #000;
  background: #fff;
  box-shadow: 0px 0px 30px #fff;
}

.label--positive {
  background: #ebff00;
  box-shadow: 0px 0px 30px #ebff00;
}

.label--negative {
  background: #ff6b00;
  box-shadow: 0px 0px 30px #ff6b00;
}

.message {
  font-size: clamp(0.8rem, 1.6949152542vw, 10rem);
  letter-spacing: 0.3em;
  text-shadow: 0px 0px 20px #ffffff, 0px 0px 20px rgba(255, 255, 255, 0.5);
  max-width: 45ch;
}