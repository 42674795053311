.root {
  position: fixed;
  top: clamp(1rem, 2.7118644068vw, 999rem);
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 90%;
  text-align: center;
  color: #fff;
  text-shadow: 0px 0px 20px #ffffff, 0px 0px 20px rgba(255, 255, 255, 0.5);
  font-size: clamp(1rem, 2.9661016949vw, 999rem);
  letter-spacing: 0.1em;
  text-transform: uppercase;
}