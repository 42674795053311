.root {
  position: fixed;
  top: 0;
  left: 0;
  padding: 0.2rem;
  padding: clamp(0.8rem, 0.078125vw, 100rem);
  will-change: transform;
  width: clamp(0.8rem, 31.25vw, 100rem);
  transition: opacity var(--global-transition-duration) ease;
  opacity: 0;
}
.root:global(.enter) {
  opacity: 0;
  transition-delay: calc(0ms + var(--global-transition-duration) * 0.75);
  transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  will-change: opacity;
}
.root:global(.appear-active), .root:global(.appear-done), .root:global(.enter-active), .root:global(.enter-done) {
  opacity: 1;
}
.root:global(.exit) {
  opacity: 1;
  will-change: opacity;
  transition-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
  transition-delay: 0ms;
}
.root:global(.exit-active) {
  opacity: 0;
}
.root:global(.exit-done) {
  display: none !important;
}

.header {
  margin-bottom: clamp(0.8rem, 1.09375vw, 100rem);
  margin-left: clamp(-100rem, -2.0833333333vw, 999rem);
}

.inventory {
  display: inline-block;
  border: 1px solid #fff;
  border-radius: 1111px;
  padding: 0 0.5em;
}

.verb--add {
  color: #ebff00;
}

.verb--remove {
  color: #7ef7e8;
}

.inner {
  transition: opacity var(--global-transition-duration) ease;
  transition: opacity var(--global-transition-duration) ease, transform var(--global-transition-duration) ease;
  opacity: 0;
  transform: translateY(3vw);
  position: absolute;
  width: 100%;
}
.inner:global(.enter) {
  opacity: 0;
  transition-delay: calc(0ms + var(--global-transition-duration) * 0.75);
  transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  will-change: opacity;
  transform: translateY(3vw);
}
.inner:global(.appear-active), .inner:global(.appear-done), .inner:global(.enter-active), .inner:global(.enter-done) {
  opacity: 1;
  transform: translateY(0);
}
.inner:global(.exit) {
  opacity: 1;
  will-change: opacity;
  transition-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
  transition-delay: 0ms;
  transform: translateY(0);
}
.inner:global(.exit-active) {
  opacity: 0;
  transform: translateY(-3vw);
}
.inner:global(.exit-done) {
  display: none !important;
}