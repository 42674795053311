@font-face {
  font-family: "Blur";
  font-weight: 400;
  src: url("./fonts/Blur-Medium.otf") format("opentype");
}

* {
  box-sizing: border-box;
}

body.experience {
  cursor: none !important;
}

body.experience * {
  cursor: none !important;
}

html,
body {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
  font-family: "Blur";
  font-weight: 400;
  // -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

body {
  font-size: 2rem;
}

button {
  font-size: 2rem;
}

body.init * {
  -webkit-user-select: none !important;
  user-select: none !important;
}

a {
  color: inherit;
}
