.wrapper {
  transition: opacity var(--global-transition-duration) ease;
  opacity: 0;
}
.wrapper:global(.enter) {
  opacity: 0;
  transition-delay: calc(0ms + var(--global-transition-duration) * 0.75);
  transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  will-change: opacity;
}
.wrapper:global(.appear-active), .wrapper:global(.appear-done), .wrapper:global(.enter-active), .wrapper:global(.enter-done) {
  opacity: 1;
}
.wrapper:global(.exit) {
  opacity: 1;
  will-change: opacity;
  transition-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
  transition-delay: 0ms;
}
.wrapper:global(.exit-active) {
  opacity: 0;
}
.wrapper:global(.exit-done) {
  display: none !important;
}

.root {
  --rotation: 0deg;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: visible;
  transition: transform 0.2s ease-in-out;
}

.root svg {
  transition: all 0.2s ease-in-out;
  transform: rotate(var(--rotation));
  width: clamp(6rem, 6.5104166667vw, 100rem);
  height: auto;
}
.root svg path {
  fill: transparent;
  stroke: white;
  stroke-width: 3px;
  transition: all 0.2s ease-in-out;
}

.root--has-focus {
  transform: translate(-50%, -50%);
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.3)) drop-shadow(0px 0px 30px #ebff00);
}
.root--has-focus svg {
  transform: rotate(var(--rotation)) scale(0.9);
}
.root--has-focus svg path {
  fill: #ebff00;
  stroke: #ebff00;
}

.root--has-focus.root--in-inventory {
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.3)) drop-shadow(0px 0px 30px #fff);
}
.root--has-focus.root--in-inventory svg path {
  fill: #7ef7e8;
  stroke: #7ef7e8;
}