.root {
  position: fixed;
  bottom: clamp(0.8rem, 1.0416666667vw, 100rem);
  right: clamp(0.8rem, 1.0416666667vw, 100rem);
  padding: 0;
  text-align: center;
  transition: opacity var(--global-transition-duration) ease;
  transition: opacity var(--global-transition-duration) ease, transform var(--global-transition-duration) ease;
  opacity: 0;
  transform: translateY(3vw);
}
.root:global(.enter) {
  opacity: 0;
  transition-delay: calc(0ms + var(--global-transition-duration) * 0.75);
  transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  will-change: opacity;
  transform: translateY(3vw);
}
.root:global(.appear-active), .root:global(.appear-done), .root:global(.enter-active), .root:global(.enter-done) {
  opacity: 1;
  transform: translateY(0);
}
.root:global(.exit) {
  opacity: 1;
  will-change: opacity;
  transition-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
  transition-delay: 0ms;
  transform: translateY(0);
}
.root:global(.exit-active) {
  opacity: 0;
  transform: translateY(-3vw);
}
.root:global(.exit-done) {
  display: none !important;
}

.header {
  margin-right: auto;
  margin-left: clamp(0.8rem, 0.5208333333vw, 100rem);
  left: 0;
  right: 0;
}

.inner {
  position: relative;
}

.type {
  position: absolute;
  color: #fff;
  text-shadow: 0.05vw 0.05vw 0.1vw rgba(0, 0, 0, 0.5), 0px 0px 0.26vw rgba(0, 0, 0, 0.4);
  font-size: clamp(0.8rem, 1.4583333333vw, 100rem);
}

.count {
  position: absolute;
  box-shadow: 0.05vw 0.05vw 0.1vw rgba(0, 0, 0, 0.5), 0px 0px 0.26vw rgba(0, 0, 0, 0.4);
  border-radius: 111px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  font-size: clamp(0.8rem, 1.0416666667vw, 100rem);
  width: 1.5em;
  height: 1.5em;
  transform: translate(-50%, -50%);
}

.count--active {
  background: #ebff00;
  color: #000;
}

@keyframes bounce {
  0% {
    transform: translate(-50%, -50%) scale(1) rotate(0);
  }
  50% {
    transform: translate(-50%, -50%) scale(2.5);
  }
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
}
.count--bounce {
  animation: bounce 0.33s ease-in-out;
}

.type--fungi {
  top: 7%;
  left: 9%;
}

.count--fungi {
  top: 4%;
  left: 23%;
}

.type--bird {
  top: 4%;
  left: 79%;
}

.count--bird {
  top: 12%;
  left: 90%;
}

.type--human {
  left: -10%;
  top: 32%;
}

.count--human {
  left: 9%;
  top: 29%;
}

.type--mountain {
  left: 67%;
  top: 28%;
}

.count--mountain {
  left: 77%;
  top: 36%;
}

.type--glacier {
  top: 43%;
  left: 78%;
}

.count--glacier {
  top: 51%;
  left: 97%;
}

.type--river {
  top: 50%;
  left: 37%;
}

.count--river {
  top: 55%;
  left: 54%;
}

.type--tree {
  top: 90%;
  left: 52%;
}

.count--tree {
  top: 87%;
  left: 60%;
}

@keyframes bounce-icon {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  0% {
    transform: scale(1);
  }
}
.inventory {
  width: clamp(0.8rem, 25vw, 100rem);
  height: auto;
  display: block;
  filter: drop-shadow(0.05vw 0.05vw 0.1vw rgba(0, 0, 0, 0.5)) drop-shadow(0px 0px 0.26vw rgba(0, 0, 0, 0.4));
}
.inventory path {
  fill: rgba(255, 255, 255, 0.1);
  stroke: rgba(255, 255, 255, 0.8);
  transition: fill 0.33s ease, stroke 0.33s ease;
}

.inventory[data-has*=Mountain] :global(#MOUNTAIN) {
  fill: #fff;
  stroke: #fff;
  animation: bounce-icon 0.33s ease-in-out;
}

.inventory[data-has*=Fungi] :global(#FUNGI) {
  fill: #fff;
  stroke: #fff;
  animation: bounce-icon 0.33s ease-in-out;
}

.inventory[data-has*=Bird] :global(#BIRD) {
  fill: #fff;
  stroke: #fff;
  animation: bounce-icon 0.33s ease-in-out;
}

.inventory[data-has*=Glacier] :global(#GLACIER) {
  fill: #fff;
  stroke: #fff;
  animation: bounce-icon 0.33s ease-in-out;
}

.inventory[data-has*=Human] :global(#HUMAN) {
  fill: #fff;
  stroke: #fff;
  animation: bounce-icon 0.33s ease-in-out;
}

.inventory[data-has*=River] :global(#RIVER) {
  fill: #fff;
  stroke: #fff;
  animation: bounce-icon 0.33s ease-in-out;
}

.inventory[data-has*=Tree] :global(#TREE) {
  fill: #fff;
  stroke: #fff;
  animation: bounce-icon 0.33s ease-in-out;
}

.hint {
  position: absolute;
  right: 0;
  top: -25%;
  width: clamp(18rem, 17.4479166667vw, 100rem);
  transition: opacity var(--global-transition-duration) ease;
  transition: opacity var(--global-transition-duration) ease, transform var(--global-transition-duration) ease;
  opacity: 0;
  transform: translateY(3vw);
}
.hint:global(.enter) {
  opacity: 0;
  transition-delay: calc(0ms + var(--global-transition-duration) * 0.75);
  transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  will-change: opacity;
  transform: translateY(3vw);
}
.hint:global(.appear-active), .hint:global(.appear-done), .hint:global(.enter-active), .hint:global(.enter-done) {
  opacity: 1;
  transform: translateY(0);
}
.hint:global(.exit) {
  opacity: 1;
  will-change: opacity;
  transition-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
  transition-delay: 0ms;
  transform: translateY(0);
}
.hint:global(.exit-active) {
  opacity: 0;
  transform: translateY(-3vw);
}
.hint:global(.exit-done) {
  display: none !important;
}

.hint-inventory {
  display: inline-block;
  border: 1px solid #fff;
  border-radius: 1111px;
  padding: 0 0.5em;
}

.validate-hint {
  position: absolute;
  right: 0;
  top: clamp(-999rem, -9.6354166667vw, 999rem);
  width: clamp(0rem, 26.5625vw, 999rem);
  transition: opacity var(--global-transition-duration) ease;
  transition: opacity var(--global-transition-duration) ease, transform var(--global-transition-duration) ease;
  opacity: 0;
  transform: translateY(3vw);
}
.validate-hint:global(.enter) {
  opacity: 0;
  transition-delay: calc(0ms + var(--global-transition-duration) * 0.75);
  transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  will-change: opacity;
  transform: translateY(3vw);
}
.validate-hint:global(.appear-active), .validate-hint:global(.appear-done), .validate-hint:global(.enter-active), .validate-hint:global(.enter-done) {
  opacity: 1;
  transform: translateY(0);
}
.validate-hint:global(.exit) {
  opacity: 1;
  will-change: opacity;
  transition-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
  transition-delay: 0ms;
  transform: translateY(0);
}
.validate-hint:global(.exit-active) {
  opacity: 0;
  transform: translateY(-3vw);
}
.validate-hint:global(.exit-done) {
  display: none !important;
}

.validate-hint__validate {
  display: inline-block;
  border-radius: 1111px;
  padding: 0 0.5em;
  background: #ebff00;
  color: #000;
  box-shadow: 0px 0px 10px #ebff00;
}