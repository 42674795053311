.root {
  padding: clamp(0.8rem, 0.5729166667vw, 100rem);
  border-radius: clamp(0.8rem, 0.5208333333vw, 100rem);
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  display: inline-block;
  border: 2px solid rgba(255, 255, 255, 0.3);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5), 0px 0px 10px rgba(0, 0, 0, 0.4);
}

.root--yellow {
  background-color: rgba(235, 255, 0, 0.9);
  color: #000;
}

.message {
  font-size: clamp(0.85rem, 1.0416666667vw, 100rem);
  line-height: 1.3em;
  max-width: 50ch;
  margin: 0;
  text-align: left;
}

.message:not(:last-child) {
  margin-bottom: clamp(0.8rem, 0.625vw, 100rem);
}

.header {
  font-size: clamp(1.2rem, 1.4583333333vw, 100rem);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
}

.header:not(:last-child) {
  margin-bottom: clamp(0.8rem, 0.625vw, 100rem);
}

.icon {
  width: clamp(1.25rem, 1.4583333333vw, 100rem);
  margin-right: clamp(0.8rem, 0.3125vw, 100rem);
  height: auto;
  flex-shrink: 0;
  margin-top: 0.12em;
  filter: drop-shadow(0px 0px 10px #fff);
}

.root--yellow .icon {
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
}

.root--yellow .icon circle {
  fill: #000;
  stroke: rgba(235, 255, 0, 0.9);
}