@font-face {
  font-family: "Blur";
  font-weight: 400;
  src: url("Blur-Medium.853399a9.otf") format("opentype");
}
* {
  box-sizing: border-box;
}
body.experience {
  cursor: none !important;
}
body.experience * {
  cursor: none !important;
}
html,
body {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
  font-family: "Blur";
  font-weight: 400;
  text-rendering: optimizeLegibility;
}
body {
  font-size: 2rem;
}
button {
  font-size: 2rem;
}
body.init * {
  -webkit-user-select: none !important;
  user-select: none !important;
}
a {
  color: inherit;
}
.root_77b47d {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity var(--global-transition-duration) ease;
  transition: opacity var(--global-transition-duration) ease, transform var(--global-transition-duration) ease;
  opacity: 0;
  transform: translateY(3vw);
}
.root_77b47d.enter {
  opacity: 0;
  transition-delay: calc(0ms + var(--global-transition-duration) * 0.75);
  transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  will-change: opacity;
  transform: translateY(3vw);
}
.root_77b47d.appear-active, .root_77b47d.appear-done, .root_77b47d.enter-active, .root_77b47d.enter-done {
  opacity: 1;
  transform: translateY(0);
}
.root_77b47d.exit {
  opacity: 1;
  will-change: opacity;
  transition-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
  transition-delay: 0ms;
  transform: translateY(0);
}
.root_77b47d.exit-active {
  opacity: 0;
  transform: translateY(-3vw);
}
.root_77b47d.exit-done {
  display: none !important;
}
.tablet_77b47d {
  max-width: clamp(0.8rem, 20.0520833333vw, 100rem);
  position: absolute;
  top: 52%;
  left: 52%;
  transition: opacity var(--global-transition-duration) ease;
  transition: opacity var(--global-transition-duration) ease, transform var(--global-transition-duration) ease;
  opacity: 0;
  transform: translateY(3vw);
}
.tablet_77b47d.enter {
  opacity: 0;
  transition-delay: calc(0ms + var(--global-transition-duration) * 0.75);
  transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  will-change: opacity;
  transform: translateY(3vw);
}
.tablet_77b47d.appear-active, .tablet_77b47d.appear-done, .tablet_77b47d.enter-active, .tablet_77b47d.enter-done {
  opacity: 1;
  transform: translateY(0);
}
.tablet_77b47d.exit {
  opacity: 1;
  will-change: opacity;
  transition-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
  transition-delay: 0ms;
  transform: translateY(0);
}
.tablet_77b47d.exit-active {
  opacity: 0;
  transform: translateY(-3vw);
}
.tablet_77b47d.exit-done {
  display: none !important;
}
.step_b0358f {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: clamp(0.8rem, 6.6666666667vw, 100rem);
  color: #ffffff;
  text-align: center;
  transition: opacity var(--global-transition-duration) ease;
  transition: opacity var(--global-transition-duration) ease, transform var(--global-transition-duration) ease;
  opacity: 0;
  transform: translateY(3vw);
}
.step_b0358f.enter {
  opacity: 0;
  transition-delay: calc(0ms + var(--global-transition-duration) * 0.75);
  transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  will-change: opacity;
  transform: translateY(3vw);
}
.step_b0358f.appear-active, .step_b0358f.appear-done, .step_b0358f.enter-active, .step_b0358f.enter-done {
  opacity: 1;
  transform: translateY(0);
}
.step_b0358f.exit {
  opacity: 1;
  will-change: opacity;
  transition-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
  transition-delay: 0ms;
  transform: translateY(0);
}
.step_b0358f.exit-active {
  opacity: 0;
  transform: translateY(-3vw);
}
.step_b0358f.exit-done {
  display: none !important;
}
.title_b0358f {
  font-size: clamp(0.8rem, 9.6354166667vw, 100rem);
  letter-spacing: -0.01em;
  line-height: 0.8em;
  text-shadow: 0px 0px 20px #ffffff, 0px 0px 20px rgba(255, 255, 255, 0.5);
}
.text_b0358f {
  font-size: clamp(0.8rem, 2.5vw, 100rem);
  line-height: 1.16em;
  text-shadow: 0.05vw 0.05vw 0.1vw rgba(0, 0, 0, 0.5), 0px 0px 0.26vw rgba(0, 0, 0, 0.4);
}
.name_b0358f {
  text-shadow: 0.05vw 0.05vw 0.1vw rgba(0, 0, 0, 0.5), 0px 0px 0.26vw rgba(0, 0, 0, 0.4);
  font-size: clamp(0.8rem, 6.25vw, 100rem);
  line-height: 0.92em;
  display: block;
  margin-bottom: clamp(0.8rem, 4.1666666667vw, 100rem);
}
.name-wrapper_b0358f {
  justify-self: flex-start;
  margin-bottom: auto;
}
.elements_b0358f {
  width: 60vw;
  margin-top: clamp(0.8rem, 4.1666666667vw, 100rem);
  height: auto;
}
.elements_b0358f .icon {
  filter: drop-shadow(0px 0px 20px #fff);
}
.elements_b0358f .text {
  filter: drop-shadow(0.05vw 0.05vw 0.1vw rgba(0, 0, 0, 0.5)) drop-shadow(0px 0px 0.26vw rgba(0, 0, 0, 0.4));
}
.character-image_b0358f {
  width: 30%;
  height: auto;
}
.root_cde38b {
  position: fixed;
  bottom: clamp(0.8rem, 1.0416666667vw, 100rem);
  right: clamp(0.8rem, 1.0416666667vw, 100rem);
  padding: 0;
  text-align: center;
  transition: opacity var(--global-transition-duration) ease;
  transition: opacity var(--global-transition-duration) ease, transform var(--global-transition-duration) ease;
  opacity: 0;
  transform: translateY(3vw);
}
.root_cde38b.enter {
  opacity: 0;
  transition-delay: calc(0ms + var(--global-transition-duration) * 0.75);
  transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  will-change: opacity;
  transform: translateY(3vw);
}
.root_cde38b.appear-active, .root_cde38b.appear-done, .root_cde38b.enter-active, .root_cde38b.enter-done {
  opacity: 1;
  transform: translateY(0);
}
.root_cde38b.exit {
  opacity: 1;
  will-change: opacity;
  transition-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
  transition-delay: 0ms;
  transform: translateY(0);
}
.root_cde38b.exit-active {
  opacity: 0;
  transform: translateY(-3vw);
}
.root_cde38b.exit-done {
  display: none !important;
}
.header_cde38b {
  margin-right: auto;
  margin-left: clamp(0.8rem, 0.5208333333vw, 100rem);
  left: 0;
  right: 0;
}
.inner_cde38b {
  position: relative;
}
.type_cde38b {
  position: absolute;
  color: #fff;
  text-shadow: 0.05vw 0.05vw 0.1vw rgba(0, 0, 0, 0.5), 0px 0px 0.26vw rgba(0, 0, 0, 0.4);
  font-size: clamp(0.8rem, 1.4583333333vw, 100rem);
}
.count_cde38b {
  position: absolute;
  box-shadow: 0.05vw 0.05vw 0.1vw rgba(0, 0, 0, 0.5), 0px 0px 0.26vw rgba(0, 0, 0, 0.4);
  border-radius: 111px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  font-size: clamp(0.8rem, 1.0416666667vw, 100rem);
  width: 1.5em;
  height: 1.5em;
  transform: translate(-50%, -50%);
}
.count--active_cde38b {
  background: #ebff00;
  color: #000;
}
@keyframes bounce_cde38b {
  0% {
    transform: translate(-50%, -50%) scale(1) rotate(0);
  }
  50% {
    transform: translate(-50%, -50%) scale(2.5);
  }
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
}
.count--bounce_cde38b {
  animation: bounce_cde38b 0.33s ease-in-out;
}
.type--fungi_cde38b {
  top: 7%;
  left: 9%;
}
.count--fungi_cde38b {
  top: 4%;
  left: 23%;
}
.type--bird_cde38b {
  top: 4%;
  left: 79%;
}
.count--bird_cde38b {
  top: 12%;
  left: 90%;
}
.type--human_cde38b {
  left: -10%;
  top: 32%;
}
.count--human_cde38b {
  left: 9%;
  top: 29%;
}
.type--mountain_cde38b {
  left: 67%;
  top: 28%;
}
.count--mountain_cde38b {
  left: 77%;
  top: 36%;
}
.type--glacier_cde38b {
  top: 43%;
  left: 78%;
}
.count--glacier_cde38b {
  top: 51%;
  left: 97%;
}
.type--river_cde38b {
  top: 50%;
  left: 37%;
}
.count--river_cde38b {
  top: 55%;
  left: 54%;
}
.type--tree_cde38b {
  top: 90%;
  left: 52%;
}
.count--tree_cde38b {
  top: 87%;
  left: 60%;
}
@keyframes bounce-icon_cde38b {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  0% {
    transform: scale(1);
  }
}
.inventory_cde38b {
  width: clamp(0.8rem, 25vw, 100rem);
  height: auto;
  display: block;
  filter: drop-shadow(0.05vw 0.05vw 0.1vw rgba(0, 0, 0, 0.5)) drop-shadow(0px 0px 0.26vw rgba(0, 0, 0, 0.4));
}
.inventory_cde38b path {
  fill: rgba(255, 255, 255, 0.1);
  stroke: rgba(255, 255, 255, 0.8);
  transition: fill 0.33s ease, stroke 0.33s ease;
}
.inventory_cde38b[data-has*=Mountain] #MOUNTAIN {
  fill: #fff;
  stroke: #fff;
  animation: bounce-icon_cde38b 0.33s ease-in-out;
}
.inventory_cde38b[data-has*=Fungi] #FUNGI {
  fill: #fff;
  stroke: #fff;
  animation: bounce-icon_cde38b 0.33s ease-in-out;
}
.inventory_cde38b[data-has*=Bird] #BIRD {
  fill: #fff;
  stroke: #fff;
  animation: bounce-icon_cde38b 0.33s ease-in-out;
}
.inventory_cde38b[data-has*=Glacier] #GLACIER {
  fill: #fff;
  stroke: #fff;
  animation: bounce-icon_cde38b 0.33s ease-in-out;
}
.inventory_cde38b[data-has*=Human] #HUMAN {
  fill: #fff;
  stroke: #fff;
  animation: bounce-icon_cde38b 0.33s ease-in-out;
}
.inventory_cde38b[data-has*=River] #RIVER {
  fill: #fff;
  stroke: #fff;
  animation: bounce-icon_cde38b 0.33s ease-in-out;
}
.inventory_cde38b[data-has*=Tree] #TREE {
  fill: #fff;
  stroke: #fff;
  animation: bounce-icon_cde38b 0.33s ease-in-out;
}
.hint_cde38b {
  position: absolute;
  right: 0;
  top: -25%;
  width: clamp(18rem, 17.4479166667vw, 100rem);
  transition: opacity var(--global-transition-duration) ease;
  transition: opacity var(--global-transition-duration) ease, transform var(--global-transition-duration) ease;
  opacity: 0;
  transform: translateY(3vw);
}
.hint_cde38b.enter {
  opacity: 0;
  transition-delay: calc(0ms + var(--global-transition-duration) * 0.75);
  transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  will-change: opacity;
  transform: translateY(3vw);
}
.hint_cde38b.appear-active, .hint_cde38b.appear-done, .hint_cde38b.enter-active, .hint_cde38b.enter-done {
  opacity: 1;
  transform: translateY(0);
}
.hint_cde38b.exit {
  opacity: 1;
  will-change: opacity;
  transition-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
  transition-delay: 0ms;
  transform: translateY(0);
}
.hint_cde38b.exit-active {
  opacity: 0;
  transform: translateY(-3vw);
}
.hint_cde38b.exit-done {
  display: none !important;
}
.hint-inventory_cde38b {
  display: inline-block;
  border: 1px solid #fff;
  border-radius: 1111px;
  padding: 0 0.5em;
}
.validate-hint_cde38b {
  position: absolute;
  right: 0;
  top: clamp(-999rem, -9.6354166667vw, 999rem);
  width: clamp(0rem, 26.5625vw, 999rem);
  transition: opacity var(--global-transition-duration) ease;
  transition: opacity var(--global-transition-duration) ease, transform var(--global-transition-duration) ease;
  opacity: 0;
  transform: translateY(3vw);
}
.validate-hint_cde38b.enter {
  opacity: 0;
  transition-delay: calc(0ms + var(--global-transition-duration) * 0.75);
  transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  will-change: opacity;
  transform: translateY(3vw);
}
.validate-hint_cde38b.appear-active, .validate-hint_cde38b.appear-done, .validate-hint_cde38b.enter-active, .validate-hint_cde38b.enter-done {
  opacity: 1;
  transform: translateY(0);
}
.validate-hint_cde38b.exit {
  opacity: 1;
  will-change: opacity;
  transition-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
  transition-delay: 0ms;
  transform: translateY(0);
}
.validate-hint_cde38b.exit-active {
  opacity: 0;
  transform: translateY(-3vw);
}
.validate-hint_cde38b.exit-done {
  display: none !important;
}
.validate-hint__validate_cde38b {
  display: inline-block;
  border-radius: 1111px;
  padding: 0 0.5em;
  background: #ebff00;
  color: #000;
  box-shadow: 0px 0px 10px #ebff00;
}
.root_8faf8c {
  font-size: clamp(1rem, 1.4583333333vw, 100rem);
  height: 1.33em;
  letter-spacing: 0.025em;
  padding: 0 0.7em;
  background: #ebff00;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 111px;
  box-shadow: 0.05vw 0.05vw 0.1vw rgba(0, 0, 0, 0.5), 0px 0px 0.26vw rgba(0, 0, 0, 0.4);
}
.root_450898 {
  padding: clamp(0.8rem, 0.5729166667vw, 100rem);
  border-radius: clamp(0.8rem, 0.5208333333vw, 100rem);
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  display: inline-block;
  border: 2px solid rgba(255, 255, 255, 0.3);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5), 0px 0px 10px rgba(0, 0, 0, 0.4);
}

.root--yellow_450898 {
  background-color: rgba(235, 255, 0, 0.9);
  color: #000;
}

.message_450898 {
  font-size: clamp(0.85rem, 1.0416666667vw, 100rem);
  line-height: 1.3em;
  max-width: 50ch;
  margin: 0;
  text-align: left;
}

.message_450898:not(:last-child) {
  margin-bottom: clamp(0.8rem, 0.625vw, 100rem);
}

.header_450898 {
  font-size: clamp(1.2rem, 1.4583333333vw, 100rem);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
}

.header_450898:not(:last-child) {
  margin-bottom: clamp(0.8rem, 0.625vw, 100rem);
}

.icon_450898 {
  width: clamp(1.25rem, 1.4583333333vw, 100rem);
  margin-right: clamp(0.8rem, 0.3125vw, 100rem);
  height: auto;
  flex-shrink: 0;
  margin-top: 0.12em;
  filter: drop-shadow(0px 0px 10px #fff);
}

.root--yellow_450898 .icon_450898 {
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
}

.root--yellow_450898 .icon_450898 circle {
  fill: #000;
  stroke: rgba(235, 255, 0, 0.9);
}
.root_2ceb89 {
  position: fixed;
  top: 0;
  left: 0;
  padding: 0.2rem;
  padding: clamp(0.8rem, 0.078125vw, 100rem);
  will-change: transform;
  width: clamp(0.8rem, 31.25vw, 100rem);
  transition: opacity var(--global-transition-duration) ease;
  opacity: 0;
}
.root_2ceb89.enter {
  opacity: 0;
  transition-delay: calc(0ms + var(--global-transition-duration) * 0.75);
  transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  will-change: opacity;
}
.root_2ceb89.appear-active, .root_2ceb89.appear-done, .root_2ceb89.enter-active, .root_2ceb89.enter-done {
  opacity: 1;
}
.root_2ceb89.exit {
  opacity: 1;
  will-change: opacity;
  transition-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
  transition-delay: 0ms;
}
.root_2ceb89.exit-active {
  opacity: 0;
}
.root_2ceb89.exit-done {
  display: none !important;
}
.header_2ceb89 {
  margin-bottom: clamp(0.8rem, 1.09375vw, 100rem);
  margin-left: clamp(-100rem, -2.0833333333vw, 999rem);
}
.inventory_2ceb89 {
  display: inline-block;
  border: 1px solid #fff;
  border-radius: 1111px;
  padding: 0 0.5em;
}
.verb--add_2ceb89 {
  color: #ebff00;
}
.verb--remove_2ceb89 {
  color: #7ef7e8;
}
.inner_2ceb89 {
  transition: opacity var(--global-transition-duration) ease;
  transition: opacity var(--global-transition-duration) ease, transform var(--global-transition-duration) ease;
  opacity: 0;
  transform: translateY(3vw);
  position: absolute;
  width: 100%;
}
.inner_2ceb89.enter {
  opacity: 0;
  transition-delay: calc(0ms + var(--global-transition-duration) * 0.75);
  transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  will-change: opacity;
  transform: translateY(3vw);
}
.inner_2ceb89.appear-active, .inner_2ceb89.appear-done, .inner_2ceb89.enter-active, .inner_2ceb89.enter-done {
  opacity: 1;
  transform: translateY(0);
}
.inner_2ceb89.exit {
  opacity: 1;
  will-change: opacity;
  transition-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
  transition-delay: 0ms;
  transform: translateY(0);
}
.inner_2ceb89.exit-active {
  opacity: 0;
  transform: translateY(-3vw);
}
.inner_2ceb89.exit-done {
  display: none !important;
}
.root_b18e10 {
  background: #ebff00;
  font-size: clamp(1.25rem, 2.0833333333vw, 100rem);
  height: 1.4em;
  padding: 0 0.35em;
  border-radius: 1111px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.root--yellow_b18e10 {
  color: #000;
  background: #ebff00;
  box-shadow: 0px 0px 30px #ebff00;
}

.root--blue_b18e10 {
  background: #7ef7e8;
  box-shadow: 0px 0px 30px #7ef7e8;
}

.type_b18e10 {
  margin-right: clamp(0.8rem, 0.4166666667vw, 100rem);
  line-height: 1em;
}

.quantity_b18e10 {
  border: 2px solid black;
  border-radius: 50%;
  font-size: clamp(0.8rem, 1.0416666667vw, 100rem);
  width: 1.5em;
  height: 1.5em;
  line-height: 1em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@keyframes bounce_b18e10 {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  0% {
    transform: scale(1);
  }
}

.root--bounce_b18e10 {
  animation: bounce_b18e10 0.5s ease-in-out;
}

.root_b18e10 > svg {
  height: 60%;
  width: auto;
  margin-right: 0.3em;
}

.root_b18e10 > svg path {
  fill: #000;
}
.root_227d9d {
  position: fixed;
  display: inline-block;
  bottom: clamp(0.8rem, 2.34375vw, 100rem);
  width: fit-content;
  max-width: 70vw;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  transition: opacity var(--global-transition-duration) ease;
  transition: opacity var(--global-transition-duration) ease, transform var(--global-transition-duration) ease;
  opacity: 0;
  transform: translateY(3vw);
}
.root_227d9d.enter {
  opacity: 0;
  transition-delay: calc(0ms + var(--global-transition-duration) * 0.75);
  transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  will-change: opacity;
  transform: translateY(3vw);
}
.root_227d9d.appear-active, .root_227d9d.appear-done, .root_227d9d.enter-active, .root_227d9d.enter-done {
  opacity: 1;
  transform: translateY(0);
}
.root_227d9d.exit {
  opacity: 1;
  will-change: opacity;
  transition-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
  transition-delay: 0ms;
  transform: translateY(0);
}
.root_227d9d.exit-active {
  opacity: 0;
  transform: translateY(-3vw);
}
.root_227d9d.exit-done {
  display: none !important;
}
.message_227d9d {
  font-size: clamp(2rem, 2.5vw, 100rem);
  line-height: 1.16em;
  text-align: center;
  color: #fff;
  margin: 0;
  text-shadow: 0.05vw 0.05vw 0.1vw rgba(0, 0, 0, 0.5), 0px 0px 0.26vw rgba(0, 0, 0, 0.4);
  width: fit-content;
}
.heading_227d9d {
  margin-bottom: clamp(0.8rem, 0.5208333333vw, 100rem);
  margin-bottom: clamp(0.8rem, 2.0833333333vw, 100rem);
}
.wrapper_1790f3 {
  transition: opacity var(--global-transition-duration) ease;
  opacity: 0;
}
.wrapper_1790f3.enter {
  opacity: 0;
  transition-delay: calc(0ms + var(--global-transition-duration) * 0.75);
  transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  will-change: opacity;
}
.wrapper_1790f3.appear-active, .wrapper_1790f3.appear-done, .wrapper_1790f3.enter-active, .wrapper_1790f3.enter-done {
  opacity: 1;
}
.wrapper_1790f3.exit {
  opacity: 1;
  will-change: opacity;
  transition-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
  transition-delay: 0ms;
}
.wrapper_1790f3.exit-active {
  opacity: 0;
}
.wrapper_1790f3.exit-done {
  display: none !important;
}
.root_1790f3 {
  --rotation: 0deg;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: visible;
  transition: transform 0.2s ease-in-out;
}
.root_1790f3 svg {
  transition: all 0.2s ease-in-out;
  transform: rotate(var(--rotation));
  width: clamp(6rem, 6.5104166667vw, 100rem);
  height: auto;
}
.root_1790f3 svg path {
  fill: transparent;
  stroke: white;
  stroke-width: 3px;
  transition: all 0.2s ease-in-out;
}
.root--has-focus_1790f3 {
  transform: translate(-50%, -50%);
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.3)) drop-shadow(0px 0px 30px #ebff00);
}
.root--has-focus_1790f3 svg {
  transform: rotate(var(--rotation)) scale(0.9);
}
.root--has-focus_1790f3 svg path {
  fill: #ebff00;
  stroke: #ebff00;
}
.root--has-focus_1790f3.root--in-inventory_1790f3 {
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.3)) drop-shadow(0px 0px 30px #fff);
}
.root--has-focus_1790f3.root--in-inventory_1790f3 svg path {
  fill: #7ef7e8;
  stroke: #7ef7e8;
}
.root_ce309f {
  position: fixed;
  bottom: clamp(0.8rem, 1.875vw, 100rem);
  left: clamp(0.8rem, 1.0416666667vw, 100rem);
  width: clamp(0.8rem, 16.6666666667vw, 100rem);
  height: clamp(0.8rem, 12.5vw, 100rem);
  transition: opacity var(--global-transition-duration) ease;
  opacity: 0;
}
.root_ce309f.enter {
  opacity: 0;
  transition-delay: calc(0ms + var(--global-transition-duration) * 0.75);
  transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  will-change: opacity;
}
.root_ce309f.appear-active, .root_ce309f.appear-done, .root_ce309f.enter-active, .root_ce309f.enter-done {
  opacity: 1;
}
.root_ce309f.exit {
  opacity: 1;
  will-change: opacity;
  transition-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
  transition-delay: 0ms;
}
.root_ce309f.exit-active {
  opacity: 0;
}
.root_ce309f.exit-done {
  display: none !important;
}
.heading_ce309f {
  position: absolute;
  bottom: 0;
  left: 0;
}
.root_500add {
  position: absolute;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  transform: translate(-50%, -50%);
}

.wrapper_500add {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: clamp(0.8rem, 1.0416666667vw, 100rem);
  height: clamp(0.8rem, 1.0416666667vw, 100rem);
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  box-shadow: 0.05vw 0.05vw 0.1vw rgba(0, 0, 0, 0.5), 0px 0px 0.26vw rgba(0, 0, 0, 0.4);
  margin-right: clamp(0.8rem, 0.2604166667vw, 100rem);
}

.wrapper_500add > svg {
  width: auto;
  height: auto;
  max-width: 70%;
  max-height: 70%;
  filter: drop-shadow(0.05vw 0.05vw 0.1vw rgba(0, 0, 0, 0.5)) drop-shadow(0px 0px 0.26vw rgba(0, 0, 0, 0.4));
}

.count_500add {
  font-size: clamp(0.8rem, 0.6510416667vw, 100rem);
  color: #fff;
  text-shadow: 0.05vw 0.05vw 0.1vw rgba(0, 0, 0, 0.5), 0px 0px 0.26vw rgba(0, 0, 0, 0.4);
}

.root--glacier_500add {
  left: 64%;
  top: 5%;
}

.root--tree_500add {
  left: 70%;
  top: 16%;
}

.root--mountain_500add {
  left: 73%;
  top: 29%;
}

.root--river_500add {
  left: 74%;
  top: 42%;
}

.root--bird_500add {
  left: 72%;
  top: 56%;
}

.root--human_500add {
  left: 68%;
  top: 68%;
}

.root--fungi_500add {
  left: 61%;
  top: 78%;
}
.root_2adaa5 {
  transition: opacity var(--global-transition-duration) ease;
  transition: opacity var(--global-transition-duration) ease, transform var(--global-transition-duration) ease;
  opacity: 0;
  transform: translateY(3vw);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.root_2adaa5.enter {
  opacity: 0;
  transition-delay: calc(0ms + var(--global-transition-duration) * 0.75);
  transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  will-change: opacity;
  transform: translateY(3vw);
}
.root_2adaa5.appear-active, .root_2adaa5.appear-done, .root_2adaa5.enter-active, .root_2adaa5.enter-done {
  opacity: 1;
  transform: translateY(0);
}
.root_2adaa5.exit {
  opacity: 1;
  will-change: opacity;
  transition-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
  transition-delay: 0ms;
  transform: translateY(0);
}
.root_2adaa5.exit-active {
  opacity: 0;
  transform: translateY(-3vw);
}
.root_2adaa5.exit-done {
  display: none !important;
}
.inner_2adaa5 {
  max-width: clamp(0.8rem, 23.9583333333vw, 100rem);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-flex;
  flex-direction: column;
}
.first_2adaa5 {
  margin-bottom: 1rem;
}
.inventory_2adaa5 {
  display: inline-block;
  border: 1px solid #fff;
  border-radius: 1111px;
  padding: 0 0.5em;
}
.yes_2adaa5 {
  display: inline-block;
  border-radius: 1111px;
  padding: 0 0.5em;
  background: #ebff00;
  color: #000;
  box-shadow: 0px 0px 10px #ebff00;
}
.no_2adaa5 {
  display: inline-block;
  border-radius: 1111px;
  padding: 0 0.5em;
  background: #ff6b00;
  color: #000;
  box-shadow: 0px 0px 10px #ff6b00;
}
.root_9245d6 {
  position: fixed;
  width: clamp(12rem, 17.4479166667vw, 100rem);
  top: 4vw;
  right: 4vw;
  transition: opacity var(--global-transition-duration) ease;
  transition: opacity var(--global-transition-duration) ease, transform var(--global-transition-duration) ease;
  opacity: 0;
  transform: translateY(3vw);
}
.root_9245d6.enter {
  opacity: 0;
  transition-delay: calc(0ms + var(--global-transition-duration) * 0.75);
  transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  will-change: opacity;
  transform: translateY(3vw);
}
.root_9245d6.appear-active, .root_9245d6.appear-done, .root_9245d6.enter-active, .root_9245d6.enter-done {
  opacity: 1;
  transform: translateY(0);
}
.root_9245d6.exit {
  opacity: 1;
  will-change: opacity;
  transition-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
  transition-delay: 0ms;
  transform: translateY(0);
}
.root_9245d6.exit-active {
  opacity: 0;
  transform: translateY(-3vw);
}
.root_9245d6.exit-done {
  display: none !important;
}
.root_9245d6 canvas {
  width: 100% !important;
  height: auto !important;
  margin-top: 1rem;
}
.first_9245d6 {
  margin-bottom: 1rem;
}
.solidarity_9245d6 {
  display: inline-block;
  border: 1px solid #fff;
  border-radius: 1111px;
  padding: 0 0.5em;
}
.root_4be27a {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1;
}

.root--disabled_4be27a::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.canvas_4be27a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
}
.root_a1b690 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: clamp(0.8rem, 1.6666666667vw, 100rem);
  text-shadow: 0.05vw 0.05vw 0.1vw rgba(0, 0, 0, 0.5), 0px 0px 0.26vw rgba(0, 0, 0, 0.4);
  margin-bottom: 4rem;
  color: #fff;
  background-color: #000;
  width: 100%;
  height: 100%;
  position: fixed;
}
.root_b905d2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.message_b905d2 {
  font-size: clamp(0.8rem, 1.6666666667vw, 100rem);
  text-shadow: 0.05vw 0.05vw 0.1vw rgba(0, 0, 0, 0.5), 0px 0px 0.26vw rgba(0, 0, 0, 0.4);
  margin-bottom: 4rem;
}

.key_b905d2 {
  font-size: clamp(0.8rem, 9.6354166667vw, 100rem);
  text-shadow: 0.05vw 0.05vw 0.1vw rgba(0, 0, 0, 0.5), 0px 0px 0.26vw rgba(0, 0, 0, 0.4);
  display: flex;
  gap: 1.5rem;
  line-height: 1em;
}

.char_b905d2 {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 2rem;
  padding: 0.1em;
  width: 1.75ch;
}
.root_1ac052 {
  position: fixed;
  top: clamp(0.8rem, 1.0416666667vw, 100rem);
  left: clamp(0.8rem, 1.0416666667vw, 100rem);
  transition: opacity var(--global-transition-duration) ease;
  transition: opacity var(--global-transition-duration) ease, transform var(--global-transition-duration) ease;
  opacity: 0;
  transform: translateY(3vw);
}
.root_1ac052.enter {
  opacity: 0;
  transition-delay: calc(0ms + var(--global-transition-duration) * 0.75);
  transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  will-change: opacity;
  transform: translateY(3vw);
}
.root_1ac052.appear-active, .root_1ac052.appear-done, .root_1ac052.enter-active, .root_1ac052.enter-done {
  opacity: 1;
  transform: translateY(0);
}
.root_1ac052.exit {
  opacity: 1;
  will-change: opacity;
  transition-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
  transition-delay: 0ms;
  transform: translateY(0);
}
.root_1ac052.exit-active {
  opacity: 0;
  transform: translateY(-3vw);
}
.root_1ac052.exit-done {
  display: none !important;
}
.header_1ac052 {
  margin-left: clamp(6rem, 5.7291666667vw, 100rem);
  margin-bottom: 0;
}
.main_1ac052 {
  font-size: clamp(1rem, 1.25vw, 100rem);
  padding: 0;
  padding-right: clamp(0.8rem, 0.8333333333vw, 100rem);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -3ch;
  position: relative;
}
.main_1ac052:before {
  content: "";
  font-size: inherit;
  height: 3ch;
  width: 100%;
  display: block;
  background-color: rgba(32, 32, 32, 0.4);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 111px;
  border: 2px solid rgba(255, 255, 255, 0.4);
  box-shadow: 0.05vw 0.05vw 0.1vw rgba(0, 0, 0, 0.5), 0px 0px 0.26vw rgba(0, 0, 0, 0.4);
}
.name_1ac052 {
  position: relative;
  margin-left: -0.8em;
}
.image_1ac052 {
  height: 13ch;
  width: auto;
  position: relative;
  margin-left: -0.5em;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.5)) drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.4));
}
.root_917536 {
  transition: opacity var(--global-transition-duration) ease;
  transition: opacity var(--global-transition-duration) ease, transform var(--global-transition-duration) ease;
  opacity: 0;
  transform: translateY(3vw);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.root_917536.enter {
  opacity: 0;
  transition-delay: calc(0ms + var(--global-transition-duration) * 0.75);
  transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  will-change: opacity;
  transform: translateY(3vw);
}
.root_917536.appear-active, .root_917536.appear-done, .root_917536.enter-active, .root_917536.enter-done {
  opacity: 1;
  transform: translateY(0);
}
.root_917536.exit {
  opacity: 1;
  will-change: opacity;
  transition-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
  transition-delay: 0ms;
  transform: translateY(0);
}
.root_917536.exit-active {
  opacity: 0;
  transform: translateY(-3vw);
}
.root_917536.exit-done {
  display: none !important;
}
.hint_917536 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: clamp(0.8rem, 20.0520833333vw, 100rem);
}
.hint_917536 span {
  display: inline-block;
}
.root_6ec367 {
  position: fixed;
  max-width: clamp(0.8rem, 22.3958333333vw, 100rem);
  top: 27%;
  bottom: 0;
  display: block;
  margin: auto;
  right: 2vw;
  transition: opacity var(--global-transition-duration) ease;
  transition: opacity var(--global-transition-duration) ease, transform var(--global-transition-duration) ease;
  opacity: 0;
  transform: translateY(3vw);
}
.root_6ec367.enter {
  opacity: 0;
  transition-delay: calc(0ms + var(--global-transition-duration) * 0.75);
  transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  will-change: opacity;
  transform: translateY(3vw);
}
.root_6ec367.appear-active, .root_6ec367.appear-done, .root_6ec367.enter-active, .root_6ec367.enter-done {
  opacity: 1;
  transform: translateY(0);
}
.root_6ec367.exit {
  opacity: 1;
  will-change: opacity;
  transition-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
  transition-delay: 0ms;
  transform: translateY(0);
}
.root_6ec367.exit-active {
  opacity: 0;
  transform: translateY(-3vw);
}
.root_6ec367.exit-done {
  display: none !important;
}
.first_6ec367:not(:last-child) {
  margin-bottom: clamp(0.8rem, 0.4166666667vw, 100rem);
}
.solidarity_6ec367 {
  display: inline-block;
  border: 1px solid #fff;
  border-radius: 1111px;
  padding: 0 0.5em;
}
.yes_6ec367 {
  display: inline-block;
  border-radius: 1111px;
  padding: 0 0.5em;
  background: #ebff00;
  color: #000;
  box-shadow: 0px 0px 10px #ebff00;
}
.no_6ec367 {
  display: inline-block;
  border-radius: 1111px;
  padding: 0 0.5em;
  background: #ff6b00;
  color: #000;
  box-shadow: 0px 0px 10px #ff6b00;
}
.stats-list_0c7b60 {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.stats-list-item_0c7b60 {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: clamp(0.8rem, 0.8333333333vw, 100rem);
}

.stats-list-item_0c7b60:not(:last-child) {
  margin-bottom: clamp(0.3rem, 0.2083333333vw, 100rem);
}

.stats-list-item__meter_0c7b60 {
  width: clamp(0.8rem, 4.6875vw, 100rem);
  height: clamp(0.8rem, 0.625vw, 100rem);
  border-radius: 111px;
  background: #000;
  display: block;
  --width: 0%;
  border: 2px solid #fff;
}

.stats-list-item__meter_0c7b60:before {
  content: "";
  display: block;
  background: #fff;
  border-radius: inherit;
  width: var(--width);
  height: 100%;
}

.about_0c7b60 {
  width: clamp(12rem, 16.1458333333vw, 100rem);
  position: absolute;
  bottom: 0;
  top: 0;
  height: fit-content;
  margin: auto;
  right: 12vw;
  transition: opacity var(--global-transition-duration) ease;
  transition: opacity var(--global-transition-duration) ease, transform var(--global-transition-duration) ease;
  opacity: 0;
  transform: translateY(3vw);
}

.about_0c7b60.enter {
  opacity: 0;
  transition-delay: calc(0ms + var(--global-transition-duration) * 0.75);
  transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  will-change: opacity;
  transform: translateY(3vw);
}

.about_0c7b60.appear-active, .about_0c7b60.appear-done, .about_0c7b60.enter-active, .about_0c7b60.enter-done {
  opacity: 1;
  transform: translateY(0);
}

.about_0c7b60.exit {
  opacity: 1;
  will-change: opacity;
  transition-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
  transition-delay: 0ms;
  transform: translateY(0);
}

.about_0c7b60.exit-active {
  opacity: 0;
  transform: translateY(-3vw);
}

.about_0c7b60.exit-done {
  display: none !important;
}

@media screen and (max-aspect-ratio: 4/3) {
  .about_0c7b60 {
    right: 3vw;
  }
}

.about-header_0c7b60 {
  width: 100%;
}

.name_0c7b60 {
  transition-delay: 0.15s;
  position: absolute;
  top: 4vw;
  left: 0;
  right: 0;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  transition: opacity var(--global-transition-duration) ease;
  transition: opacity var(--global-transition-duration) ease, transform var(--global-transition-duration) ease;
  opacity: 0;
  transform: translateY(3vw);
}

.name_0c7b60.enter {
  opacity: 0;
  transition-delay: calc(99ms + var(--global-transition-duration) * 0.75);
  transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  will-change: opacity;
  transform: translateY(3vw);
}

.name_0c7b60.appear-active, .name_0c7b60.appear-done, .name_0c7b60.enter-active, .name_0c7b60.enter-done {
  opacity: 1;
  transform: translateY(0);
}

.name_0c7b60.exit {
  opacity: 1;
  will-change: opacity;
  transition-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
  transition-delay: 99ms;
  transform: translateY(0);
}

.name_0c7b60.exit-active {
  opacity: 0;
  transform: translateY(-3vw);
}

.name_0c7b60.exit-done {
  display: none !important;
}

.type_0c7b60 {
  display: inline-block;
  border-radius: 1111px;
  padding: 0 0.5em;
  background: #ebff00;
  color: #000;
  box-shadow: 0px 0px 10px #ebff00;
}

.icon_0c7b60 {
  height: clamp(5rem, 4.9479166667vw, 100rem);
  max-width: clamp(5rem, 4.9479166667vw, 100rem);
  margin-top: clamp(0.8rem, 0.4166666667vw, 100rem);
  width: auto;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.root_f33231 {
  position: fixed;
  top: 70%;
  left: 0;
  right: 0;
  width: 42%;
  height: auto;
  margin: auto;
  transition: all 0.8s ease;
  opacity: 0;
  transform: translateY(33%);
}
.root_f33231.enter {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  will-change: opacity;
  transform: translateY(33%);
}
.root_f33231.appear-active, .root_f33231.appear-done, .root_f33231.enter-active, .root_f33231.enter-done {
  opacity: 1;
  transform: translateY(0);
}
.root_f33231.exit {
  opacity: 1;
  will-change: opacity;
  transition-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
  transform: translateY(0);
}
.root_f33231.exit-active {
  opacity: 0;
  transform: translateY(33%);
}
.root_f33231.exit-done {
  display: none !important;
}
.root_c57846 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.message_c57846 {
  font-size: clamp(0.8rem, 1.6666666667vw, 100rem);
  text-shadow: 0.05vw 0.05vw 0.1vw rgba(0, 0, 0, 0.5), 0px 0px 0.26vw rgba(0, 0, 0, 0.4);
  margin-bottom: 4rem;
}
.root_3503e8 {
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
}

.inner_3503e8 {
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
  transition: opacity var(--global-transition-duration) ease;
  transition: opacity var(--global-transition-duration) ease, transform var(--global-transition-duration) ease;
  opacity: 0;
  transform: translateY(3vw);
}

.inner_3503e8.enter {
  opacity: 0;
  transition-delay: calc(0ms + var(--global-transition-duration) * 0.75);
  transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  will-change: opacity;
  transform: translateY(3vw);
}

.inner_3503e8.appear-active, .inner_3503e8.appear-done, .inner_3503e8.enter-active, .inner_3503e8.enter-done {
  opacity: 1;
  transform: translateY(0);
}

.inner_3503e8.exit {
  opacity: 1;
  will-change: opacity;
  transition-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
  transition-delay: 0ms;
  transform: translateY(0);
}

.inner_3503e8.exit-active {
  opacity: 0;
  transform: translateY(-3vw);
}

.inner_3503e8.exit-done {
  display: none !important;
}
.root_b54d98 {
  display: flex;
  flex-grow: 1;
  flex-basis: 100%;
  appearance: none;
  background: transparent;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  font-family: "Blur";
  color: #fff;
  cursor: pointer;
}

.label_b54d98:not(:last-child) {
  margin-bottom: 3rem;
}

.label_b54d98 {
  font-size: clamp(0.8rem, 3.5593220339vw, 99rem);
  height: 1.5em;
  letter-spacing: 0.02em;
  padding: 0 0.4em 0 0.5em;
  border-radius: 1111px;
  white-space: nowrap;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #000;
  transition: filter 0.1s ease;
}

.label_b54d98 > svg {
  height: 55%;
  width: auto;
  margin-left: 0.5rem;
  padding: 1px;
  flex-shrink: 0;
}

.root_b54d98:active .label_b54d98 {
  filter: invert(1);
}

.label--none_b54d98 {
  color: #fff;
  text-shadow: 0px 0px 20px #ffffff, 0px 0px 20px rgba(255, 255, 255, 0.5);
  background: transparent;
  box-shadow: none;
  padding: 0;
}

.label--neutral_b54d98 {
  color: #000;
  background: #fff;
  box-shadow: 0px 0px 30px #fff;
}

.label--positive_b54d98 {
  background: #ebff00;
  box-shadow: 0px 0px 30px #ebff00;
}

.label--negative_b54d98 {
  background: #ff6b00;
  box-shadow: 0px 0px 30px #ff6b00;
}

.message_b54d98 {
  font-size: clamp(0.8rem, 1.6949152542vw, 10rem);
  letter-spacing: 0.3em;
  text-shadow: 0px 0px 20px #ffffff, 0px 0px 20px rgba(255, 255, 255, 0.5);
  max-width: 45ch;
}
.root_37b7ed {
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
}
.root_12ca82 {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  user-select: none !important;
  -webkit-user-select: none !important;
}

.root_12ca82 * {
  user-select: none !important;
  -webkit-user-select: none !important;
}

.pad_12ca82 {
  width: 100%;
  height: 100%;
  position: absolute;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pad_12ca82 > button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-color: transparent;
  appearance: none;
  border: none;
}

@media (hover: hover) {
  .pad_12ca82 > button {
    display: none;
  }
}

.touch_12ca82 {
  position: absolute;
  width: 12rem;
  height: 12rem;
  opacity: 0;
  transition: opacity 0.1s ease;
}

.touch_12ca82:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12rem;
  height: 12rem;
  border: 10px solid var(--color);
  border-radius: 11111px;
  transform: translate(-50%, -50%);
  filter: drop-shadow(0px 0px 10px var(--color)) drop-shadow(0px 0px 10px var(--color));
  transition: all 0.1s ease;
}

.touch--is-visible_12ca82 {
  opacity: 1;
  transition-delay: 0.1s;
}

.move_12ca82 {
  position: absolute;
  width: 16rem;
  height: 16rem;
  margin-top: -8rem;
  margin-left: -8rem;
  opacity: 0;
  transition: all 0.1s ease;
}

.move_12ca82 > svg {
  width: 100%;
  height: 100%;
  filter: drop-shadow(0px 0px 10px var(--color));
  transition: all 0.1s ease;
}

.move--is-visible_12ca82 {
  opacity: 1;
}

.move_12ca82:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8rem;
  height: 8rem;
  border: 10px solid var(--color);
  border-radius: 1111px;
  filter: drop-shadow(0px 0px 10px var(--color));
  transition: all 0.1s ease;
  transform: translate(-50%, -50%);
}

.move_12ca82:after,
.touch_12ca82:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: 60%;
  background: var(--color);
  border-radius: 111px;
  box-shadow: 0px 0px 10px var(--color);
  transition: all 0.1s ease;
  opacity: 0;
}

.touch_12ca82:after {
  width: 8rem;
  height: 8rem;
}

.move_12ca82:after {
  width: 5rem;
  height: 5rem;
}

.move--can-click_12ca82:after {
  opacity: 1;
}

.touch--can-click_12ca82:after {
  opacity: 1;
}
.root_b5719f {
  position: fixed;
  top: clamp(1rem, 2.7118644068vw, 999rem);
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 90%;
  text-align: center;
  color: #fff;
  text-shadow: 0px 0px 20px #ffffff, 0px 0px 20px rgba(255, 255, 255, 0.5);
  font-size: clamp(1rem, 2.9661016949vw, 999rem);
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.root_8a5ca2 {
  appearance: none;
  font-size: clamp(1rem, 2.9661016949vw, 999rem);
  font-size: clamp(0.8rem, 3.5593220339vw, 99rem);
  height: 1.5em;
  letter-spacing: 0.02em;
  padding: 0 0.4em 0 0.5em;
  border-radius: 1111px;
  white-space: nowrap;
  background: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  font-family: "Blur";
  cursor: pointer;
  position: fixed;
  bottom: clamp(1rem, 2.9661016949vw, 999rem);
  border-radius: 111px;
  color: #000;
  transition: filter 0.1s ease;
  transition: opacity var(--global-transition-duration) ease;
  transition: opacity var(--global-transition-duration) ease, transform var(--global-transition-duration) ease;
  opacity: 0;
  transform: translateY(3vw);
  white-space: nowrap;
}
.root_8a5ca2 > svg {
  height: 55%;
  width: auto;
  margin-left: 0.5rem;
  padding: 1px;
  flex-shrink: 0;
}
.root_8a5ca2.enter {
  opacity: 0;
  transition-delay: calc(0ms + var(--global-transition-duration) * 0.75);
  transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  will-change: opacity;
  transform: translateY(3vw);
}
.root_8a5ca2.appear-active, .root_8a5ca2.appear-done, .root_8a5ca2.enter-active, .root_8a5ca2.enter-done {
  opacity: 1;
  transform: translateY(0);
}
.root_8a5ca2.exit {
  opacity: 1;
  will-change: opacity;
  transition-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
  transition-delay: 0ms;
  transform: translateY(0);
}
.root_8a5ca2.exit-active {
  opacity: 0;
  transform: translateY(-3vw);
}
.root_8a5ca2.exit-done {
  display: none !important;
}
.root_8a5ca2:before {
  width: 150%;
  height: 200%;
  left: -25%;
  top: -50%;
  content: "";
  display: block;
  position: absolute;
}
.root_8a5ca2:active {
  filter: invert(1);
}
.root--grey_8a5ca2 {
  background: #202020;
  color: #fff;
}
.root--left_8a5ca2 {
  left: clamp(1rem, 2.9661016949vw, 999rem);
}
.root--right_8a5ca2 {
  right: clamp(1rem, 2.9661016949vw, 999rem);
  background-color: #ebff00;
  box-shadow: 0px 0px 30px #ebff00;
}
.root_733836 {
  background: #000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.lines_733836 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.canvas_733836 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.root_649ac5 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  background-color: black;
  font-size: clamp(0.8rem, 1.6666666667vw, 100rem);
  padding: 9rem;
  text-align: center;
}

.form_649ac5 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input_649ac5 {
  font-size: clamp(0.8rem, 1.6666666667vw, 100rem);
}

.confirm_649ac5 {
  font-size: clamp(0.8rem, 1.6666666667vw, 100rem);
}

.title_649ac5 {
  margin-top: 0;
}
.root_12de3b {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: #000;
  color: #fff;
  background-image: url("take-home-bg.ebc01344.png");
  background-size: 45px 45px;
  background-position: center;
  padding-bottom: 5rem;
}

.section_12de3b {
  padding: 0 1rem;
  max-width: 130rem;
  margin-left: auto;
  margin-right: auto;
}

.title_12de3b {
  margin: 1em 0;
  max-width: 30ch;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: clamp(2rem, 7.7vw, 5rem);
  text-align: center;
}

.chain_12de3b {
  border: 1px solid #fff;
  display: inline-block;
  border-radius: 111px;
  padding: 0 0.35em;
}

.intro_12de3b {
  font-size: clamp(1.5rem, 5vw, 3rem);
  max-width: 40ch;
  text-align: center;
  margin: 2em auto;
}

.article-title_12de3b {
  font-size: min(10vw, 8rem);
  text-align: center;
  line-height: 0.9em;
  max-width: 14ch;
  margin: 1.5em auto;
}

.caps_12de3b {
  text-transform: uppercase;
}

.article_12de3b {
  font-size: clamp(1.5rem, 5vw, 3rem);
  max-width: 40ch;
  margin-left: auto;
  margin-right: auto;
}

.listen_12de3b {
  margin: min(15vw, 7rem) auto;
}

.silent_12de3b {
  font-size: clamp(1rem, 2vw, 2rem);
  margin: auto;
  text-align: center;
  margin-bottom: 1.5em;
}

.button_12de3b {
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  font-family: "Blur";
  background: #fff;
  border: none;
  outline: none;
  font-size: clamp(1.5rem, 3vw, 2rem);
  padding: 0 0.8em 0 0.8em;
  border-radius: 111px;
  transition: all 0.1s ease;
  box-shadow: 0px 0px 20px 5px white;
  color: #000;
  height: 1.66em;
  margin: auto;
}

.button_12de3b:disabled {
  opacity: 0.4;
  pointer-events: none;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 0px 0px 20px 5px rgba(255, 255, 255, 0.3);
}

.button_12de3b > svg {
  height: 70%;
  width: auto;
  margin-left: 0.3em;
}

.elements-wrapper_12de3b {
  margin: 4rem 0;
  max-width: 70rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  display: block;
}

.elements_12de3b {
  width: 100%;
  height: auto;
  filter: drop-shadow(0px 0px 8px #fff);
}

.count_12de3b {
  position: absolute;
  left: 0;
  color: #000;
  background: #fff;
  border-radius: 111px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: clamp(0.8rem, 2vw, 1.5rem);
  transform: translate(-50%, -50%);
  width: 2.5ch;
  height: 2.5ch;
}

.count_12de3b:empty {
  display: none;
}

.count--human_12de3b {
  top: 20%;
  left: 8%;
}

.count--mountain_12de3b {
  top: 20%;
  left: 46%;
}

.count--river_12de3b {
  top: 5%;
  left: 73%;
}

.count--glacier_12de3b {
  top: 57%;
  left: 33%;
}

.count--fungi_12de3b {
  top: 55%;
  left: 44%;
}

.count--tree_12de3b {
  top: 69%;
  left: 65%;
}

.count--bird_12de3b {
  top: 56%;
  left: 90%;
}
.root_c4b25c {
  width: 100%;
  height: 100vw;
  max-width: 100vh;
  max-height: 100vh;
  position: relative;
  margin: auto;
}

.canvas_c4b25c {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.loading_c4b25c {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: min(5vw, 3.8rem);
}
/*# sourceMappingURL=index.5cb051c4.css.map */
