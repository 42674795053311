.root {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: clamp(0.8rem, 1.6666666667vw, 100rem);
  text-shadow: 0.05vw 0.05vw 0.1vw rgba(0, 0, 0, 0.5), 0px 0px 0.26vw rgba(0, 0, 0, 0.4);
  margin-bottom: 4rem;
  color: #fff;
  background-color: #000;
  width: 100%;
  height: 100%;
  position: fixed;
}