.root {
  position: fixed;
  max-width: clamp(0.8rem, 22.3958333333vw, 100rem);
  top: 27%;
  bottom: 0;
  display: block;
  margin: auto;
  right: 2vw;
  transition: opacity var(--global-transition-duration) ease;
  transition: opacity var(--global-transition-duration) ease, transform var(--global-transition-duration) ease;
  opacity: 0;
  transform: translateY(3vw);
}
.root:global(.enter) {
  opacity: 0;
  transition-delay: calc(0ms + var(--global-transition-duration) * 0.75);
  transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  will-change: opacity;
  transform: translateY(3vw);
}
.root:global(.appear-active), .root:global(.appear-done), .root:global(.enter-active), .root:global(.enter-done) {
  opacity: 1;
  transform: translateY(0);
}
.root:global(.exit) {
  opacity: 1;
  will-change: opacity;
  transition-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
  transition-delay: 0ms;
  transform: translateY(0);
}
.root:global(.exit-active) {
  opacity: 0;
  transform: translateY(-3vw);
}
.root:global(.exit-done) {
  display: none !important;
}

.first:not(:last-child) {
  margin-bottom: clamp(0.8rem, 0.4166666667vw, 100rem);
}

.solidarity {
  display: inline-block;
  border: 1px solid #fff;
  border-radius: 1111px;
  padding: 0 0.5em;
}

.yes {
  display: inline-block;
  border-radius: 1111px;
  padding: 0 0.5em;
  background: #ebff00;
  color: #000;
  box-shadow: 0px 0px 10px #ebff00;
}

.no {
  display: inline-block;
  border-radius: 1111px;
  padding: 0 0.5em;
  background: #ff6b00;
  color: #000;
  box-shadow: 0px 0px 10px #ff6b00;
}