.stats-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.stats-list-item {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: clamp(0.8rem, 0.8333333333vw, 100rem);
}

.stats-list-item:not(:last-child) {
  margin-bottom: clamp(0.3rem, 0.2083333333vw, 100rem);
}

.stats-list-item__meter {
  width: clamp(0.8rem, 4.6875vw, 100rem);
  height: clamp(0.8rem, 0.625vw, 100rem);
  border-radius: 111px;
  background: #000;
  display: block;
  --width: 0%;
  border: 2px solid #fff;
}

.stats-list-item__meter:before {
  content: "";
  display: block;
  background: #fff;
  border-radius: inherit;
  width: var(--width);
  height: 100%;
}

.about {
  width: clamp(12rem, 16.1458333333vw, 100rem);
  position: absolute;
  bottom: 0;
  top: 0;
  height: fit-content;
  margin: auto;
  right: 12vw;
  transition: opacity var(--global-transition-duration) ease;
  transition: opacity var(--global-transition-duration) ease, transform var(--global-transition-duration) ease;
  opacity: 0;
  transform: translateY(3vw);
}
.about:global(.enter) {
  opacity: 0;
  transition-delay: calc(0ms + var(--global-transition-duration) * 0.75);
  transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  will-change: opacity;
  transform: translateY(3vw);
}
.about:global(.appear-active), .about:global(.appear-done), .about:global(.enter-active), .about:global(.enter-done) {
  opacity: 1;
  transform: translateY(0);
}
.about:global(.exit) {
  opacity: 1;
  will-change: opacity;
  transition-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
  transition-delay: 0ms;
  transform: translateY(0);
}
.about:global(.exit-active) {
  opacity: 0;
  transform: translateY(-3vw);
}
.about:global(.exit-done) {
  display: none !important;
}
@media screen and (max-aspect-ratio: 4/3) {
  .about {
    right: 3vw;
  }
}

.about-header {
  width: 100%;
}

.name {
  transition-delay: 0.15s;
  position: absolute;
  top: 4vw;
  left: 0;
  right: 0;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  transition: opacity var(--global-transition-duration) ease;
  transition: opacity var(--global-transition-duration) ease, transform var(--global-transition-duration) ease;
  opacity: 0;
  transform: translateY(3vw);
}
.name:global(.enter) {
  opacity: 0;
  transition-delay: calc(99ms + var(--global-transition-duration) * 0.75);
  transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
  will-change: opacity;
  transform: translateY(3vw);
}
.name:global(.appear-active), .name:global(.appear-done), .name:global(.enter-active), .name:global(.enter-done) {
  opacity: 1;
  transform: translateY(0);
}
.name:global(.exit) {
  opacity: 1;
  will-change: opacity;
  transition-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
  transition-delay: 99ms;
  transform: translateY(0);
}
.name:global(.exit-active) {
  opacity: 0;
  transform: translateY(-3vw);
}
.name:global(.exit-done) {
  display: none !important;
}

.type {
  display: inline-block;
  border-radius: 1111px;
  padding: 0 0.5em;
  background: #ebff00;
  color: #000;
  box-shadow: 0px 0px 10px #ebff00;
}

.icon {
  height: clamp(5rem, 4.9479166667vw, 100rem);
  max-width: clamp(5rem, 4.9479166667vw, 100rem);
  margin-top: clamp(0.8rem, 0.4166666667vw, 100rem);
  width: auto;
  margin-left: auto;
  margin-right: auto;
  display: block;
}